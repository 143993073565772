import ProjectGrid from "./components/ProjectGrid";
import {createGlobalStyle} from "styled-components";
import Logo from "./components/Logo";

function App() {
    return (
        <>
            <Logo/>
            <ProjectGrid/>
            <GlobalStyle/>
        </>
    );
}

export default App;


const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    color: #454545;
  }
`
