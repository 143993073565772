import styled from 'styled-components';

const ProjectCard = ({project}) => {

    const handleDownload=(link)=>{
        let element = document.createElement('a')
        element.setAttribute('href',link)
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
    }



    return (
        <ProjectCardStyled>
            <img className="projectImage" src={project.imgUrl} alt=""/>
            <div className="descriptionHolder">
                <h2 className="projectTitle">{project.title}</h2>
                <p className="projectDescription">{project.description}</p>
                <button
                    className="getProjectButton"
                    onClick={
                        project.buttonType === "LINK"
                            ? (() => window.open(project.link, "_blank"))
                            : (() => handleDownload(project.link))
                    }
                >
                    {project.buttonText}
                </button>
            </div>
        </ProjectCardStyled>

    )
}

export default ProjectCard

const ProjectCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  width: 350px;
  height: 450px;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, .2);
  overflow: hidden;
  transition: all 0.2s;

  :hover {
    transform: scale(1.02);
  }

  .projectImage {
    height: 60%;
    object-fit: scale-down;
  }

  .descriptionHolder {
    height: 40%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .projectTitle {
      text-align: center;
    }

    .projectDescription {
      text-align: center;
    }

    .getProjectButton {
      height: 20%;
      width: 150px;
      background: none;
      border: 2px solid #B39DDB;
      color: #B39DDB;
      border-radius: 10px;
      cursor: pointer;
      outline: 0;
      font-size:1rem;

      :hover {
        background: rgba(179, 157, 219, 0.4);
      }
    }

  }
`