import styled from 'styled-components';
import ProjectCard from "./ProjectCard";


const ProjectGrid = () => {

    const projects = [


        {
            id:3,
            title:'Diverseay.fr',
            description:"Site internet du bureau d'études",
            imgUrl:'images/diverseaty.png',
            buttonType:'LINK',
            link:'https://diverseaty.fr',
            buttonText:'Visitez'
        },

        {
            id:4,
            title:'Go4Lunch',
            description:'Application Android - Où mangent vos collègues?',
            imgUrl:'images/go4Lunch3.png',
            buttonType:'DOWNLOAD',
            link:'images/go4Lunch.apk',
            buttonText:"Téléchargez l'appli"
        }
        ,
        {
            id:1,
            title:'Music Player',
            description:'Application ReactJs - Lecteur de musique',
            imgUrl:'images/music.png',
            buttonType:'LINK',
            link:'http://music.bosbizz.fr',
            buttonText:'Visitez'
        },
        {
            id:6,
            title:'Ignite',
            description:'Application ReactJs - Trouvez les derniers jeux à la mode',
            imgUrl:'images/ignite.png',
            buttonType:'LINK',
            link:'http://games.bosbizz.fr',
            buttonText:"Visitez"
        },
        {
            id:5,
            title:'Real Estate Manager',
            description:'Application Android - Gestion de biens immobiliers',
            imgUrl:'images/rem.png',
            buttonType:'DOWNLOAD',
            link:'images/rem.apk',
            buttonText:"Téléchargez l'appli"
        },

        {
            id:7,
            title:'Photos',
            description:'Application ReactJs - Site internet portefolio',
            imgUrl:'images/capture.png',
            buttonType:'LINK',
            link:'http://photo.bosbizz.fr/',
            buttonText:"Visitez"
        },
        {
            id:8,
            title:'Pizza Shop',
            description:'Application ReactJs - Préparez votre pizza en ligne',
            imgUrl:'images/pizza.png',
            buttonType:'LINK',
            link:'http://pizza.bosbizz.fr/',
            buttonText:"Visitez"
        },{
            id:2,
            title:'Mon CV',
            description:'Découvrez mon parcours',
            imgUrl:'images/cv.png',
            buttonType:'DOWNLOAD',
            link:'images/cv.pdf',
            buttonText:'Téléchargez'
        },
        {
            id:9,
            title:'Amazing Flow',
            description:'Application Android - UI Sandbox Flow',
            imgUrl:'images/amazing.png',
            buttonType:'DOWNLOAD',
            link:'images/amazing.apk',
            buttonText:'Téléchargez'
        }




    ]

    return (
        <ProjectGridStyled>
            {projects.map((project)=><ProjectCard key={project.id} project={project}/>)}
        </ProjectGridStyled>
    );
};

const ProjectGridStyled = styled.div`
  max-width: 80%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 8rem;
  row-gap: 3rem;
  align-items: center;
  justify-content: center;
  margin: 4rem auto;
`
export default ProjectGrid